import { useContext } from "react";

import { StyledStandardSpacer } from "components/styles/common/structure/StandardSpacer.styled";
import { StyledStandardEndSection } from "components/styles/common/structure/StandardEndSection.styled";

import TopHeadingBanner from "components/objects/common/TopHeadingBanner";

import Roadmap from "_components/features/docs/pricing/Roadmap";
import LoneCtaSection from "components/sections/common/LoneCtaSection";

import { AccountContext } from "components/contexts/AccountContext";

const Pricing = () => {
  const [loggedIn] = useContext(AccountContext);

  return (
    <StyledStandardEndSection>
      <TopHeadingBanner $pos="55%" src="/images/bettercalltim-gfx.jpg">
        <h1>Pricing</h1>
        <p>How our pricing system works</p>
      </TopHeadingBanner>
      <StyledStandardSpacer $paddingTop="50px" />
      <Roadmap />

      <StyledStandardSpacer $paddingTop="300px" />

      {loggedIn ? (
        <LoneCtaSection to="/profile?tab=payment">
          Purchase Services
        </LoneCtaSection>
      ) : (
        <LoneCtaSection to="/join">Join Now</LoneCtaSection>
      )}
    </StyledStandardEndSection>
  );
};

export default Pricing;
