import React from "react";
import { useNavigate } from "react-router-dom";

import { StyledInfoHover } from "_components/atoms/buttons/styles/InfoHover.styled";

import {
  StyledDocSection,
  IndentedP,
} from "_components/features/docs/main/Documentation.styled";
import {
  StyleTRH,
  Table,
} from "_components/molecules/displays/styles/DataTable.styled";
import { LinkSpan } from "_components/atoms/inline/SpanElements";

const Rating = () => {
  const navigate = useNavigate();

  return (
    <>
      <StyledDocSection>
        <p className="title">Overview</p>
        <IndentedP>
          Your <i>package</i> is the company tier option you selected for your
          account.
        </IndentedP>
        <IndentedP>
          This dictates how much you pay for editing and thumbnails services.
          The higher your package, the higher quality of everything. This
          includes the members, skill and quality of your production team. Your
          priority within our company. The amount of benefits via games and
          production which you recieve. You being first to hearing and receiving
          everything and much much more!
        </IndentedP>
        <IndentedP>
          You can change your <i>package</i> at any time in{" "}
          <LinkSpan to="/pricing">pricing</LinkSpan>.
        </IndentedP>
        <IndentedP>
          Remember, you are only ever charged by us when you purchase edits
          and/or thumbnails, in any quantity, at any time which suits you.
        </IndentedP>
      </StyledDocSection>

      <StyledDocSection>
        <p className="title">Partners</p>
        <Table title="Company heirarchy">
          <tr>
            <th></th>
            <th>Standard</th>
            <th>Expert</th>
            <th>Elite</th>
          </tr>
          <tr>
            <StyleTRH>
              Max cut from ownership % pool per game
              <StyledInfoHover
                $translate="translate(0, -8px)"
                $inline="start"
                $length="x"
                // $text="Example: If a game has a total % pool of 15% available, and you are 'Standard' rating. Then your maximum % you can hold on that game is (15% x 1/5) = 3% (max)"
                $text="Learn about ownership percentages under the 'Games' tab"
                onClick={() => navigate("/docs/terms-of-service?tab=games")}
              />
            </StyleTRH>
            <td>1/5</td>
            <td>2/5</td>
            <td>3/5</td>
          </tr>
          <tr>
            <StyleTRH>Min cut from ownership % pool per game</StyleTRH>
            <td>
              1% (constant)
              <StyledInfoHover
                $translate="translate(0, -8px)"
                $inline="start"
                $length="x"
                $noPointer
                $text="This is the lowest minimum % ownership you can have on a game"
              />
            </td>
            <td>1/5</td>
            <td>2/5</td>
          </tr>
          <tr>
            <StyleTRH>In/out-game advertising</StyleTRH>
            <td>Out-of-game only</td>
            <td>Out-of-game & minor features in-game</td>
            <td>Out-of-game & full features in-game</td>
          </tr>
          <tr>
            <StyleTRH>Revisions Entitled</StyleTRH>
            <td>1</td>
            <td>2</td>
            <td>3</td>
          </tr>
        </Table>
      </StyledDocSection>
    </>
  );
};

export default Rating;
