import React, { useState, useEffect } from "react";

import { StyledResult } from "_components/themes/calculator/Calculator.styled";

const editIncrease = 0.2;
const editMinimum = 1;

const thumbnailIncrease = 0.5;
const thumbnailMinimum = 5;

const PriceCalculator = ({ $noHeader, lowerBracket }) => {
  const [editPrice, setEditPrice] = useState("");
  const [thumbnailPrice, setThumbnailPrice] = useState(0);

  const correctPriceDecimal = (price) => {
    price = price.toString();
    let ending = price.split(".")[1];

    if (ending && ending.length > 1) {
      price = price.slice(0, price.length - (ending.length - 2));
    }

    if (!ending) {
      price = price + ".00";
    } else if (ending.length === 1) {
      price = price + "0";
    }
    return price;
  };

  const observePushPricings = () => {
    let editPrice = correctPriceDecimal(
      lowerBracket * editIncrease + editMinimum
    );
    let thumbnailPrice = correctPriceDecimal(
      lowerBracket * thumbnailIncrease + thumbnailMinimum
    );

    setEditPrice(`${editPrice}`);
    setThumbnailPrice(`${thumbnailPrice}`);
  };

  useEffect(observePushPricings, [lowerBracket]);

  return (
    <div className="block">
      {!$noHeader && <h3>Push Pricings</h3>}
      <StyledResult $isEdit>
        Price per <span className="underlined">Edit</span>
        <br />
        <span className="value">${editPrice}</span>
      </StyledResult>
      <br />
      <StyledResult>
        Price per <span className="underlined">Thumbnail</span>
        <br />
        <span className="value">${thumbnailPrice}</span>
      </StyledResult>
    </div>
  );
};

export default PriceCalculator;
